import {
  Facebook,
  Instagram,
  LinkedIn,
  Twitter,
  Youtube,
} from '@components/common/icons/custom-icons'
import { SOCIAL_LINKS } from '@lib/data/footer'
import { Trans } from '@lingui/macro'

const SocialLinksFold = () => (
  <div className="flex flex-col space-y-4 text-white">
    <p className="text-xl text-white font-600">
      <Trans id="footer.followus">Follow Us On</Trans>
    </p>
    <a
      href={SOCIAL_LINKS.facebook}
      target="_blank"
      rel="noreferrer"
      aria-label="Codingal Facebook page link"
      className="flex space-x-2 hover:underline"
    >
      <Facebook className="w-5" />
      <span>Facebook</span>
    </a>
    <a
      href={SOCIAL_LINKS.twitter}
      target="_blank"
      rel="noreferrer"
      aria-label="Codingal Twitter page link"
      className="flex space-x-2 hover:underline"
    >
      <Twitter className="w-5" />
      <span>Twitter</span>
    </a>
    <a
      href={SOCIAL_LINKS.youtube}
      target="_blank"
      rel="noreferrer"
      aria-label="Codingal Youtube page link"
      className="flex space-x-2 hover:underline"
    >
      <Youtube className="w-5" />
      <span>Youtube</span>
    </a>
    <a
      href={SOCIAL_LINKS.linkedIn}
      target="_blank"
      rel="noreferrer"
      aria-label="Codingal LinkedIn Profile link"
      className="flex space-x-2 hover:underline"
    >
      <LinkedIn className="w-5" />
      <span>Linkedin</span>
    </a>
    <a
      href={SOCIAL_LINKS.instagram}
      target="_blank"
      rel="noreferrer"
      aria-label="Codingal Instagram page link"
      className="flex space-x-2 hover:underline"
    >
      <Instagram className="w-5" />
      <span>Instagram</span>
    </a>
  </div>
)

export default SocialLinksFold
