import {
  BEST_SELLING_COURSES,
  COMPANY_LINKS,
  TRENDING_ARTICLES,
} from '@lib/data/footer'
import { Trans } from '@lingui/macro'
import MsgTrans from '@components/common/i18n/message-trans'
import SocialLinksFold from '@components/common/footer/social-links-fold'

const InternalLinksFold = () => (
  <div className="flex flex-col mt-16 space-y-10 lg:flex-row md:mt-0 lg:space-x-20 lg:space-y-0">
    <SocialLinksFold />
    <div className="flex flex-col">
      <p className="text-xl text-white font-600">
        <Trans id="footer.Company">Company</Trans>
      </p>
      <div className="flex flex-col mt-5 space-y-3 text-white">
        {COMPANY_LINKS.map((item, i) => (
          <a href={item.link} key={i} className="hover:underline">
            <MsgTrans msg={item.label} />
          </a>
        ))}
      </div>
    </div>
    <div className="flex flex-col">
      <p className="text-xl text-white font-600">
        <Trans id="footer.bestSellingCourses">Bestselling Courses</Trans>
      </p>
      <div className="flex flex-col mt-5 space-y-3 text-white">
        {BEST_SELLING_COURSES.map(({ courseName, courseLink }) => (
          <a
            href={courseLink}
            key={courseName?.id}
            target="_blank"
            rel="noopener noreferrer"
            className="hover:underline"
          >
            <MsgTrans msg={courseName} />
          </a>
        ))}
      </div>
    </div>
    <div className="flex flex-col">
      <p className="text-xl text-white font-600">
        <Trans id="footer.trendingArticles">Trending Articles</Trans>
      </p>
      <div className="flex flex-col mt-5 space-y-3 text-white">
        {TRENDING_ARTICLES.map((guide) => (
          <a
            key={guide.title.id}
            target="_blank"
            rel="noreferrer noopener"
            href={guide.link}
            className="hover:underline"
          >
            <Trans id={guide.title.id} />
          </a>
        ))}
      </div>
    </div>
  </div>
)

export default InternalLinksFold
