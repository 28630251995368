import { FastlyImage } from '@components/common/image'
import { LOGO_THEME } from '@lib/constants/common'
import { Trans } from '@lingui/macro'

const MissionFold = () => (
  <div className="mt-10">
    <div className="flex items-center">
      <FastlyImage
        src={`/images/logos/logos-${LOGO_THEME}/logo-white.svg`}
        height={30}
        width={30}
        alt="codingal White icon"
      />
      <span className="text-xl text-white font-600 ml-2">
        <Trans>Codingal mission</Trans>
      </span>
    </div>
    <p className="mt-3 text-white">
      <Trans>
        Codingal is on a mission to inspire school kids and teens to fall in
        love with coding. Coding is proven to develop creativity, logical
        thinking and problem solving skills in kids. Coding is an essential
        skill of the future and more than 60% of all jobs in STEM-related fields
        require knowledge of code. Kids who start learning to code at a young
        age are guaranteed to become leaders, creators and entrepreneurs of the
        future.
      </Trans>
    </p>
  </div>
)

export default MissionFold
