import { currentYear } from '@lib/utils/date-native'
import { Trans } from '@lingui/macro'
import Link from 'next/link'

const TermsAndPolicy = () => (
  <div className="flex lg:flex-row flex-col text-sm text-center space-x-2 mb-5 lg:mb-0">
    <Trans>&copy; CODINGAL {currentYear}</Trans> - <br className="lg:hidden" />
    <div className="flex flex-wrap space-x-2 items-center">
      <Link href="/terms/" prefetch={false}>
        <a className="hover:underline" target="_blank" rel="noreferrer">
          <Trans>TERMS OF SERVICE</Trans>
        </a>
      </Link>
      <div className="h-3 bg-grey-700" style={{ width: '1px' }} />
      <Link href="/privacy-policy/" prefetch={false}>
        <a className="hover:underline" target="_blank" rel="noreferrer">
          <Trans>PRIVACY POLICY</Trans>
        </a>
      </Link>
      <div className="h-3 bg-grey-700" style={{ width: '1px' }} />
      <Link href="/refund-policy/" prefetch={false}>
        <a className="hover:underline" target="_blank" rel="noreferrer">
          <Trans>REFUND POLICY</Trans>
        </a>
      </Link>
      <div className="h-3 bg-grey-700" style={{ width: '1px' }} />
      <Link href="/payment-policy/" prefetch={false}>
        <a className="hover:underline" target="_blank" rel="noreferrer">
          <Trans>PAYMENT POLICY</Trans>
        </a>
      </Link>
    </div>
  </div>
)

export default TermsAndPolicy
