import { COUNTRIES_WITH_PHYSICAL_ADDRESS } from '@lib/constants/geo-lp'
import Divider from '@components/common/footer/divider'
import { useRouter } from 'next/router'

const LocationFold = ({ countryCode }) => {
  const countryCodes = Object.keys(COUNTRIES_WITH_PHYSICAL_ADDRESS)
  const { pathname } = useRouter()

  if (!countryCodes.includes(countryCode) && countryCode !== 'IN') return null
  // To display Indian office addres along with USA office address only on about us page for Indian users
  if (countryCode === 'IN' && pathname !== '/about-us') return null
  return (
    <>
      {countryCode === 'IN' && (
        <>
          <Divider />
          <div className="text-white mt-10">
            <h4 className="text-xl font-600">
              Codingal&apos; s registered office
            </h4>
            <h5 className="text-xl font-600">
              {COUNTRIES_WITH_PHYSICAL_ADDRESS['US'].country}
            </h5>
            <p>{COUNTRIES_WITH_PHYSICAL_ADDRESS['US'].address}</p>
          </div>
          <div className="text-white mt-5">
            <h5 className="text-xl font-600">India</h5>
            <p>Codingal Technologies Pvt. Ltd.</p>
            <p>
              3rd Floor, House 147, 5th Main Rd, Rajiv Gandhi Nagar, Sector 7,
              HSR Layout, Bengaluru, Karnataka 560102
            </p>
          </div>
        </>
      )}
      {countryCode !== 'IN' && (
        <>
          <Divider />
          <div className="text-white mt-10">
            <h4 className="text-xl font-600">
              Codingal&apos; s registered office in{' '}
              {COUNTRIES_WITH_PHYSICAL_ADDRESS[countryCode].country}
            </h4>
            <p>{COUNTRIES_WITH_PHYSICAL_ADDRESS[countryCode].address}</p>
          </div>
        </>
      )}
    </>
  )
}

export default LocationFold
