import { staticFile } from '@common/utils'
import { LOGO_THEME } from '@lib/constants/common'

type ImageElementProps = JSX.IntrinsicElements['img']

const LOGOS = {
  MAIN: `/images/logos/logos-${LOGO_THEME}/logo.svg`,
  WHITE: `/images/logos/logos-${LOGO_THEME}/logo-white.svg`,
  MAIN_WITH_TEXT: `/images/logos/logos-${LOGO_THEME}/logo-with-text.svg`,
  WHITE_WITH_TEXT: `/images/logos/logos-${LOGO_THEME}/white-logo-with-text.svg`,
  MAIN_WITH_WHITE_TEXT: `/images/logos/logos-${LOGO_THEME}/logo-with-white-text.svg`,
  MAIN_WITH_TAGLINE: `/images/logos/logos-${LOGO_THEME}/logo-with-tagline.svg`,
}

type LogoProps = ImageElementProps & {
  withText?: boolean
  white?: boolean
  whiteText?: boolean
  withTagline?: boolean
}

const Logo = ({
  withText,
  withTagline,
  white,
  whiteText,
  ...props
}: LogoProps) => {
  let src = LOGOS.MAIN
  if (withText && white) {
    src = LOGOS.WHITE_WITH_TEXT
  } else if (withText && whiteText) {
    src = LOGOS.MAIN_WITH_WHITE_TEXT
  } else if (withText) {
    src = LOGOS.MAIN_WITH_TEXT
  } else if (white) {
    src = LOGOS.WHITE
  } else if (withTagline) {
    src = LOGOS.MAIN_WITH_TAGLINE
  }
  return <img {...props} src={staticFile(src)} />
}

export default Logo
