import { Trans } from '@lingui/macro'
import { Mail, Phone } from 'react-feather'

const ContactFold = ({ countryCode }) => (
  <div className="w-full md:w-auto relative flex flex-col px-5 md:px-12 py-6 mt-10 space-y-5 bg-white rounded-lg shadow lg:flex-row lg:justify-between lg:space-x-12 lg:space-y-0 lg:mt-0">
    <div className="flex flex-col text-xl text-center lg:text-left text-grey-800 font-600 lg:font-700">
      <Trans>
        <p>Got questions?</p>
        <p>Contact us anytime.</p>
      </Trans>
    </div>
    <div className="flex flex-col">
      <p className="ml-6 text-grey-800 font-600">
        <Trans>Send us a message</Trans>
      </p>
      <div className="flex items-center">
        <span className="flex items-center justify-center w-5 h-5 rounded-full bg-grey-200">
          <Mail className="text-orange" size={16} />
        </span>
        <a
          className="ml-1 text-xl text-orange font-600"
          href="mailto:support@codingal.com"
        >
          support@codingal.com
        </a>
      </div>
    </div>

    <PhNumber countryCode={countryCode} />
  </div>
)

const PhNumber = ({ countryCode }) => {
  if (!['US', 'IN'].includes(countryCode)) return null

  const PHONE_NUMBERS = {
    US: '+1 (510) 361-9534',
    IN: '+91 6361 158952',
  }

  return (
    <div className="flex flex-col">
      <p className="ml-6 text-grey-800 font-600">
        <Trans>Call us</Trans>
      </p>
      <div className="flex items-center">
        <span className="flex items-center justify-center w-5 h-5 rounded-full bg-grey-200">
          <Phone className="text-orange" size={16} />
        </span>
        <a
          className="ml-1 text-xl text-orange font-600"
          href={`tel:${PHONE_NUMBERS[countryCode].replace(/\s/g, '')}`}
        >
          {PHONE_NUMBERS[countryCode]}
        </a>
      </div>
    </div>
  )
}

export default ContactFold
